import React, { Component } from 'react';

export class ErrorLog extends Component {
  static displayName = ErrorLog.name;

  constructor(props) {
    super(props);
    this.state = { messages: [], loading: true };
    this.reload = this.reload.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(this.reload, 60000);
    this.populateMessages();
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  static renderMessageTable(messages) {
    var i = 0;
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Equipment ID</th>
            <th>Timestamp</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {messages.map(msg =>
            <tr key={++i}>
              <td>{msg.equipmentID}</td>
              <td>{msg.timestamp}</td>
              <td>{msg.message}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : ErrorLog.renderMessageTable(this.state.messages);

    return (
      <div>
        <h1 id="tabelLabel" >Error Log</h1>
        <p>Displays decoding errors etc.</p>
        <button onClick={this.reload}>Reload</button>
        <button onClick={this.clear}>Clear</button>
        <br />
        {contents}
      </div>
    );
  }

  async populateMessages() {
    const response = await fetch('errorlog');
    const data = await response.json();
    this.setState({ messages: data, loading: false });
  }

  async reload() {
    this.populateMessages();
  }

  async clear() {
    const response = await fetch('errorlog', {
      method: 'DELETE'
    });
    this.setState({ messages: [] });
  }
}
