import { MessageLog } from "./components/MessageLog";
import { ErrorLog } from "./components/ErrorLog";
import { CxHeartBeat } from "./components/CxHeartBeat";
import { CxCleaningData } from "./components/CxCleaningData";
import { CxAlarm } from "./components/CxAlarm";
import { CxSensorData } from "./components/CxSensorData";
import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/message-log',
    element: <MessageLog />
  },
  {
    path: '/error-log',
    element: <ErrorLog />
  },
  {
    path: '/cx-heartbeat',
    element: <CxHeartBeat />
  },
  {
    path: '/cx-cleaning-data',
    element: <CxCleaningData />
  },
  {
    path: '/cx-alarm',
    element: <CxAlarm />
  },
  {
    path: '/cx-sensor-data',
    element: <CxSensorData />
  }
];

export default AppRoutes;
