import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Hello, B&C!</h1>
        <p>Welcome to the CX demo application:</p>
        <ul>
          <li><a href='/message-log'>Message Log</a> displays all received MQTT topics.</li>
          <li><a href='/error-log'>Error Log</a> displays decoding errors etc.</li>
          <li><a href='/cx-heartbeat'>CX Heartbeat</a> displays the status of all CX devices.</li>
          <li><a href='/cx-cleaning-data'>CX Cleaning Data</a> displays the cleaning data of all CX devices.</li>
          <li><a href='/cx-alarm'>CX Alarm</a> displays alarms of all CX devices.</li>
          <li><a href='/cx-sensor-data'>CX Sensor Data</a> displays sensor data of all CX devices.</li>
        </ul>
        <p>You may publish to the following topics:</p>
        <ul>
          <li><b>APAG/#</b></li>
          <li><b>devices/cloudex/+/heartbeat</b></li>
          <li><b>devices/cloudex/+/cleaningdata</b></li>
          <li><b>devices/cloudex/+/alerts</b></li>
          <li><b>devices/cloudex/+/sensordata</b></li>
        </ul>
        <p># is a wildcard for any number of topic path levels, + is a wildcard for a single topic path level, such as the MQTT client ID.</p>
      </div>
    );
  }
}
